<template>
  <Bar
    v-if="isShowChart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    chart-id="chart"
    dataset-id-key="label"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  data () {
    return {
      isShowChart: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      },
      chartData: {
        labels: [],
        datasets: [{
          label: this.label,
          backgroundColor: [],
          data: []
        }]
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    Bar
  },
  watch: {
    async data (newValue) {
      this.isShowChart = false
      this.chartData.labels = newValue?.labels
      this.chartData.datasets[0].data = newValue?.data
      this.chartData.datasets[0].backgroundColor = newValue?.backgroundColor
      await this.$nextTick()
      this.isShowChart = true
    }
  },
}
</script>
