<template>
  <PieChartGenerator
    v-if="isShowChart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    chart-id="chart"
    dataset-id-key="label"
  />
</template>

<script>
import { Pie as PieChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
)

export default {
  name: 'PieChart',
  data () {
    return {
      isShowChart: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: ['#ffffff', '#ff6a00', '#2bff00', '#fff200', '#00d5ff', '#0015ff', '#b30c00'],
          label: this.label,
          data: []
        }]
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    PieChartGenerator
  },
  watch: {
    async data (newValue) {
      this.isShowChart = false
      this.chartData.labels = newValue?.labels
      this.chartData.datasets[0].data = newValue?.data
      this.chartData.datasets[0].backgroundColor = newValue?.backgroundColor
      await this.$nextTick()
      this.isShowChart = true
    }
  },
}
</script>