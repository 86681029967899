<template>
  <LineChartGenerator
    v-if="isShowChart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    chart-id="chart"
    dataset-id-key="label"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'salesStatistic',
  data () {
    return {
      isShowChart: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      },
      chartData: {
        labels: [],
        datasets: [{
          backgroundColor: '#f87979',
          label: this.label,
          data: []
        }]
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    LineChartGenerator
  },
  watch: {
    async data (newValue) {
      this.isShowChart = false
      this.chartData.labels = newValue?.labels
      this.chartData.datasets[0].data = newValue?.data
      await this.$nextTick()
      this.isShowChart = true
    }
  }
}
</script>

