<template>
  <div
    class="sales-statistic-wrapper page"
  >
    <alert
      type="warning"
      title="Внимание"
      description="Здесь отображается статистика за предыдущие 3 месяца!"
    />

    <el-card
      v-loading="!getIsDataLoadedSales"
      class="box-card box-control"
    >
      <div class="box-control-title">График продаж</div>

      <line-chart
        :data="getDataSales"
      />
    </el-card>

    <el-card
      v-loading="!getIsDataLoadedSalesToOneMountPrice"
      class="box-card box-control"
    >
      <div class="box-control-title">График продаж за месяц по дням недели</div>

      <bar-chart
        :data="getDataSalesToOneMountPrice"
      />
    </el-card>

    <el-card
      v-loading="!getIsDataLoadedPurchasePriseByDate"
      class="box-card box-control"
    >
      <div class="box-control-title">Себестоимость продаж</div>

      <div class="block-buttons margin-top-0">
        <label class="main-label">
          <span class="main-label__title">Диапазон дат</span>

          <el-date-picker
            :value="dateRangePurchase"
            type="daterange"
            align="right"
            start-placeholder="Дата начала"
            end-placeholder="Дата окончания"
            :default-value="defaultDate"
            @input="selectDatePurchase"
            @change="changeDatePurchase"
          />
        </label>
      </div>

      <div class="box-card-info margin-top-10 margin-bottom-10">
        <p class="card-info__value"><span>Цена закупки</span> - {{ getPriceToFormat(getInfoPurchasePrice) }} р.</p>
        <p class="card-info__value"><span>Цена продажи</span> - {{ getPriceToFormat(getInfoTotalProfit) }} р.</p>
      </div>

      <pie-chart
        :data="getDataPurchasePriseByDate"
      />
    </el-card>

    <el-card
      v-loading="!getIsDataLoadedSumForTypePayByDate"
      class="box-card box-control"
    >
      <div class="box-control-title">Статистика по способам оплаты</div>

      <div class="block-buttons margin-top-0">
        <label class="main-label">
          <span class="main-label__title">Диапазон дат</span>

          <el-date-picker
            :value="dateRangeTypePay"
            type="daterange"
            align="right"
            start-placeholder="Дата начала"
            end-placeholder="Дата окончания"
            :default-value="defaultDate"
            @input="selectDateTypePay"
            @change="changeDateTypePay"
          />
        </label>
      </div>

      <div class="box-card-info margin-top-10 margin-bottom-10">
        <p class="card-info__value"><span>Терминал</span> - {{ getPriceToFormat(getInfoSumForTypePayByDate.terminal) }} р.</p>
        <p class="card-info__value"><span>Наличные</span> - {{ getPriceToFormat(getInfoSumForTypePayByDate.cash) }} р.</p>
        <p class="card-info__value"><span>Перевод</span> - {{ getPriceToFormat(getInfoSumForTypePayByDate.translation) }} р.</p>
      </div>

      <pie-chart
        :data="getDataSumForTypePayByDate"
      />
    </el-card>

    <el-card
      v-loading="!getIsDataLoadedSalesToProducts"
      class="box-card box-control"
    >
      <div class="box-control-title">Топ 15 товаров по кол. продаж</div>

      <pie-chart
        :data="getDataSalesToProducts"
      />
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '@/components/alert'
import LineChart from '@/components/lineChart'
import BarChart from '@/components/barChart'
import PieChart from '@/components/pieChart'
import moment from 'moment/moment'
import { getPriceToFormat } from '@/helper'

export default {
  name: 'salesStatistic',
  components: {
    Alert,
    LineChart,
    BarChart,
    PieChart
  },
  data () {
    return {
      dateRangePurchase: [
        moment().subtract(3, 'days').format(),
        moment().format()
      ],
      dateRangeTypePay: [
        moment().subtract(3, 'days').format(),
        moment().format()
      ],
      defaultDate: moment().format()
    }
  },
  computed: {
    ...mapGetters('workingShift', ['getRawData', 'getIsDataLoaded']),
    ...mapGetters('statistic', [
      'getDataSales',
      'getDataSalesToOneMountPrice',
      'getDataSalesToProducts',
      'getDataPurchasePriseByDate',
      'getDataSumForTypePayByDate',
      'getIsDataLoadedSales',
      'getIsDataLoadedSalesToOneMountPrice',
      'getIsDataLoadedSalesToProducts',
      'getIsDataLoadedPurchasePriseByDate',
      'getIsDataLoadedSumForTypePayByDate'
    ]),
    getInfoPurchasePrice () {
      return this.getDataPurchasePriseByDate?.info?.purchasePrice || 0
    },
    getInfoTotalProfit () {
      return this.getDataPurchasePriseByDate?.info?.totalProfit || 0
    },
    getInfoSumForTypePayByDate () {
      return this.getDataSumForTypePayByDate?.info || {
        terminal: 0,
        cash: 0,
        translation: 0
      }
    }
  },
  async created () {
    this.loadData().then(() => {
      this.loadDataStatistic()
    })
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    ...mapActions('statistic', [
      'loadDataSales',
      'loadDataSalesToOneMountPrice',
      'loadDataSalesToProducts',
      'loadDataPurchasePriseByDate',
      'loadDataSumForTypePayByDate'
    ]),
    getPriceToFormat,
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataWorkingShift()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    async loadDataStatistic () {
      await Promise.all([
        this.loadDataSales(),
        this.loadDataSalesToOneMountPrice(),
        this.loadDataSalesToProducts(),
        this.loadDataPurchasePriseByDate(this.dateRangePurchase),
        this.loadDataSumForTypePayByDate(this.dateRangeTypePay)
      ])
    },
    selectDatePurchase (values) {
      this.dateRangePurchase = [
        moment(values[0]).format(),
        moment(values[1]).format()
      ]
    },
    changeDatePurchase (date) {
      this.loadDataPurchasePriseByDate(date)
    },
    selectDateTypePay (values) {
      this.dateRangeTypePay = [
        moment(values[0]).format(),
        moment(values[1]).format()
      ]
    },
    changeDateTypePay (date) {
      this.loadDataSumForTypePayByDate(date)
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />